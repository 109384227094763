<!-- Start Main Banner Area -->
<div class="main-banner-area">
  <div class="container" *ngIf="SelectedLanguage == 'en'">
    <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
      <h1>
        {{Content.title}}
        <ngx-typed-js [strings]="['Cosmetics and personal care', 'Detergents and Chemical', 'Paints', 'Lead-acid Starter batteries ', 'Safety Shoes', 'Building and Construction', 'Lubricants']" [typeSpeed]="100" [loop]="true" [backSpeed]="100" [showCursor]="false">
          <span class="typing"></span>
        </ngx-typed-js>
      </h1>
      <p>{{Content.paragraph}}</p>


    </div>
  </div>
  <div class="container" *ngIf="SelectedLanguage == 'ar'">
    <div class="main-banner-content" *ngFor="let Content of mainBannerContentAr;">
      <h1>
        {{Content.title}}
        <ngx-typed-js [strings]="['المستحضرات التجميل والعناية الشخصية', 'المنظفات والمنتجات الكيميائية', 'الأصباغ والدهانات', 'البطاريات الرصاص الحمضية لبدء الحركة', 'أحذية السلامة', 'منتجات مواد البناء والتشييد', 'زيوت التشحيم']" [typeSpeed]="100" [loop]="true" [backSpeed]="100" [showCursor]="false">
          <span class="typing"></span>
        </ngx-typed-js>
      </h1>
      <p>{{Content.paragraph}}</p>


    </div>
  </div>
</div>
<!-- End Main Banner Area -->
