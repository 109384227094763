import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

  public SelectedLanguage: string = "en";
  constructor(public translate: TranslateService, private route: ActivatedRoute) {
    if (this.translate.currentLang != undefined) {

      this.SelectedLanguage = this.translate.currentLang;
    }
  }

    ngOnInit(): void {
    


    }

    mainBannerContent = [
        {
        title: 'Categories',
        paragraph: 'We can issue a certificate of conformity for different categories of products...',
            popularSearchList: [
                {
                title: 'Cosmetics and personal care',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Detergents and chemical',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Paints',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Lead-acid Starter batteries',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Safety Shoes',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Building and Construction',
                    link: 'grid-listings-left-sidebar'
                },
                {
                  title: 'Lubricants',
                    link: 'grid-listings-left-sidebar'
                }
            ]
        }
    ]


  mainBannerContentAr = [
    {
      title: 'التصنيفات',
      paragraph: 'يمكننا إصدار شهادة المطابقة لعدة أصناف من المنتجات والتي تعتبر إلزامية لحصول على تصريح قبل دخولها الى منافذ الإستيراد...',
      popularSearchList: [
        {
          title: 'المستحضرات التجميل والعناية الشخصية',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'المنظفات والمنتجات الكيميائية',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'الأصباغ والدهانات',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'البطاريات الرصاص الحمضية لبدء الحركة',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'أحذية السلامة',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'منتجات مواد البناء والتشييد',
          link: 'grid-listings-left-sidebar'
        },
        {
          title: 'زيوت التشحيم',
          link: 'grid-listings-left-sidebar'
        }
      ]
    }
  ]

}
