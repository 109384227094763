import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { BlogModule } from '../../appshared/webTools/blog/blog.module';
import { BlogService } from '../../appshared/webTools/blog/blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  constructor(public service: BlogService, public translate: TranslateService, private toastr: ToastrService, private router: Router) {
    if (this.translate.currentLang != undefined) {

      this.SelectedLanguage = this.translate.currentLang;
    }
  }
  blogGrid: number = 1;
  public SelectedLanguage: string = "en";
  public apiURL: string = "";

  public singleBlogPost: BlogModule[];

  ngOnInit(): void {
    this.apiURL = environment.apiUrl2 + 'Resources/Attachment/Order/';
    this.getAllCategory();
  }

  public getAllCategory = () => {
    //this.loader.open();
    this.service.getServiceAll()
      .subscribe(res => {
        this.singleBlogPost = res as BlogModule[];
        //  this.loader.close();
      },
        err => {
          //  this.loader.close(); console.log(err);
        });
  }

}
