<app-navbar-style-two></app-navbar-style-two>

<!-- Start Map Area -->
<div id="map">
  <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1828.2762695150725!2d58.147675009610815!3d23.584508983278003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjPCsDM1JzA0LjEiTiA1OMKwMDgnNDcuNSJF!5e0!3m2!1sen!2som!4v1679545329811!5m2!1sen!2som"></iframe>

</div>
<!-- End Map Area -->
<!-- Start Contact Info Area -->
<section class="contact-info-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="contact-info-box">
          <div class="back-icon">
            <i class='bx bx-map'></i>
          </div>
          <div class="icon">
            <i class='bx bx-map'></i>
          </div>
          <h3>{{'OurAddress' | translate }}</h3>
          <p>{{'OurAddressTXT' | translate }}</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="contact-info-box">
          <div class="back-icon">
            <i class='bx bx-phone-call'></i>
          </div>
          <div class="icon">
            <i class='bx bx-phone-call'></i>
          </div>
          <h3>{{'EmailUs' | translate }}</h3>
          <p>+968 22650787 / 93525921</p>
          <p>contact@tawtheeqconformity.com</p>
        </div>
      </div>


      <div class="col-lg-4 col-md-6">
        <div class="contact-info-box">
          <div class="back-icon">
            <i class='bx bx-time-five'></i>
          </div>
          <div class="icon">
            <i class='bx bx-time-five'></i>
          </div>
          <h3>{{'HoursofOperation' | translate }}</h3>
          <p>{{'HoursofOperationTXT' | translate }}</p>
          <p>{{'HoursofOperation2TXT' | translate }}</p>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- End Contact Info Area -->
<!-- Start Contact Area -->
<section class="contact-area pb-100">
  <div class="container">
    
    <div class="section-title">
      <h2>{{'DropUsALine' | translate }}</h2>
      <p>{{'DropUsALineTXT' | translate }}</p>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="contact-image">
          <img src="assets/img/contact.png" alt="image">
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="contact-form">
          <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
            <div class="row">
              <div class="col-lg-12 col-md-6">
                <div class="form-group">
                  <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="{{'Your_name' | translate }}">
                  <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                    <div *ngIf="name.errors.required">{{'Name_is_required' | translate }}</div>
                    <div *ngIf="name.errors.minlength"> {{ name.errors.minlength.requiredLength }} {{'character' | translate }}.</div>
                    <div *ngIf="name.errors.maxlength">{{'Name_is_maximum_50_character' | translate }}</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-6">
                <div class="form-group">
                  <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="{{'Your_email_address' | translate }}">
                  <div class="alert alert-danger" *ngIf="email.touched && !email.valid">{{'Email_Address_is_required' | translate }}</div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="{{'Your_phone_number' | translate }}">
                  <div class="alert alert-danger" *ngIf="number.touched && !number.valid">{{'Phone_number_is_required' | translate }}</div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <!--<label>{{'EnquiryType' | translate }}</label>-->
                  <select required ngModel #enquiryType="ngModel" name="enquiryType" class="form-control" >
                    <option *ngFor="let option of categoryData">
                    <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                    </ng-template>
                    <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                    <ng-template #other_contentEn>{{ option.nameEn }}</ng-template>
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="enquiryType.touched && !enquiryType.valid">{{'Enquiry_Type_is_required' | translate }}</div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="6" class="form-control" placeholder="{{'Write_your_message' | translate }}"></textarea>
                  <div class="alert alert-danger" *ngIf="message.touched && !name.valid">{{'Message_is_required' | translate }}</div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">{{'SendMessage' | translate }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Contact Area -->

<app-footer-style-two></app-footer-style-two>
