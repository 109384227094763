import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryModule } from '../../appshared/general/category/category.module';
import { CategoryService } from '../../appshared/general/category/category.service';
import { FeedbackModule } from '../../appshared/webTools/feedback/feedback.module';
import { FeedbackService } from '../../appshared/webTools/feedback/feedback.service';
import { AppLoaderService } from '../../common/app-loader/app-loader.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(public service: FeedbackService, public servCategory: CategoryService, public translate: TranslateService, private toastr: ToastrService, private router: Router) {
    if (this.translate.currentLang != undefined) {

      this.SelectedLanguage = this.translate.currentLang;
    }
  }

  public SelectedLanguage: string = "en";

  public categoryData: CategoryModule[];
  ngOnInit(): void {

    this.getAllCategory();
  }

  public getAllCategory = () => {
    //this.loader.open();
    this.servCategory.getCategory("Enguiry Type")
      .subscribe(res => {
        this.categoryData = res as CategoryModule[];
      //  this.loader.close();
      },
        err => {
        //  this.loader.close(); console.log(err);
        });
  }

  submit(form) {
    var name = form.name;
    console.log(name);

    var email = form.email;
    console.log(email);

    var number = form.number;
    console.log(number);

    var message = form.message;
    console.log(message);

    this.service.formData.name = form.name;
    this.service.formData.email = form.email;
    this.service.formData.phoneNo = form.number;
    this.service.formData.gnCategoryID = form.enquiryType;
    this.service.formData.message = form.message;


    if (!this.validateEmail(this.service.formData.email)) {
      return;
    }
    if (this.service.formData.wbFeedbackID == 0)
      this.insertRecord();
  }


  insertRecord() {
    this.service.formData.dialing = '+968';
    this.service.postService().subscribe(
      res => {
        /* this.serviceData2 = res as CategoryModule;*/

        this.toastr.success('Added Successfully!', '');

        this.service.formData = new FeedbackModule();
        this.router.navigate(['home']);

        // this.snackBar.open('Added Successfully!', 'OK', { duration: 4000 });
      },
      err => { console.log(err); }
    );
  }

  validateEmail(email) {
    var emailTest = true;
    if (email != '') {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      console.log(re.test(String(email).toLowerCase()))
      emailTest = re.test(String(email).toLowerCase())
      if (emailTest != true) {
        this.toastr.warning("Please enter valid email")

      }

    }
    return emailTest;
  }
}
