<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url('assets/img/page-title/page-title1.jpg');">
  <div class="container">
    <div class="page-title-content">
      <h2>{{'AboutUs' | translate }}</h2>
      <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li>
        <li>{{'AboutUs' | translate }}</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->
<!-- Start About Area -->
<section class="about-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <h2>{{'AboutUs' | translate }}</h2>
          <!--<span><strong>{{Content.subTitle}}</strong></span>-->
          <p>{{'AboutUsTX' | translate }}</p>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img src="./assets/img/about-img.jpg" alt="image">
        </div>
      </div>
    </div>

    <div class="row align-items-center">

      <h3>{{'OurVision' | translate }}</h3>
      <p>{{'OurVisionTXT' | translate }}</p>

    </div>
    <br />
    <br />
    <div class="row align-items-center">

      <h3>{{'OurMission' | translate }}</h3>
      <p>{{'OurMissionTXT' | translate }}</p>

    </div>

  </div>
</section>
<!-- End About Area -->


<app-footer-style-two></app-footer-style-two>
