<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-style-two" style="background-image: url('assets/img/page-title/page-title4.jpg');">
  <div class="container">
    <div class="page-title-content">
      <h2>{{'Blog' | translate }}</h2>
      <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li>
        <li>{{'Blog' | translate }}</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->
<!-- Start Blog Area -->
<section class="blog-area bg-f9f9f9 ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6" *ngFor="let Content of singleBlogPost | paginate: { itemsPerPage: 6, currentPage: blogGrid }">
        <div class="single-blog-post">
          <div class="post-image">

            <a routerLink="/blog-details;id={{Content.wbBlogID}}" class="d-block">
              <img [src]="apiURL + Content.imageLink" alt="image">
            </a>
          </div>
          <div class="post-content">
            <ul class="post-meta d-flex align-items-center">
              <li>
                <div class="d-flex align-items-center">
                  <img src="./assets/img/face-7.jpg" alt="image">
                  <span>{{Content.addBy}}</span>
                </div>
              </li>
              <li><i class="flaticon-calendar"></i> {{Content.addDate | date  : 'dd/MM/yyyy'}}</li>
            </ul>
            <h3>
              <a routerLink="/blog-details;id={{Content.wbBlogID}}">

                <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                </ng-template>
                <ng-template #other_contentAr>{{ Content.nameAr }}</ng-template>
                <ng-template #other_contentEn>{{ Content.nameEn }}</ng-template>
              </a>
            </h3>
            <a routerLink="/blog-details;id={{Content.wbBlogID}}" class="link-btn"><i class="flaticon-right-arrow"></i></a>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12">
        <div class="pagination-area text-center">
          <pagination-controls (pageChange)="blogGrid = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Blog Area -->

<app-footer-style-two></app-footer-style-two>
