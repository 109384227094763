import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStoreService } from '../local-store.service';

@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {

  CurrentLangSrc: string = "assets/img/us-flag.jpg";
  CurrentLangName: string = "Eng";
  CurrentLangCode: string = "en";

  SecondLangSrc: string = "assets/img/arab-flag.jpg";
  SecondLangName: string = "عربي";
  SecondLangCode: string = "ar";
  currentLang: string = "en";

  constructor(private localS: LocalStoreService, public translate: TranslateService) { }

  ngOnInit(): void {
    if (this.localS.getItem('Lang') == 'ar') {
      this.currentLang = "ar";
    }

    if (this.currentLang !== 'ar') {

      this.CurrentLangSrc = "assets/img/us-flag.jpg";
      this.CurrentLangName = "Eng";
      this.CurrentLangCode = "en";

      this.SecondLangSrc = "assets/img/arab-flag.jpg";
      this.SecondLangName = "عربي";
      this.SecondLangCode = "ar";
    }
    else if (this.currentLang === 'ar') {
      this.SecondLangSrc = "assets/img/us-flag.jpg";
      this.SecondLangName = "Eng";
      this.SecondLangCode = "en";

      this.CurrentLangSrc = "assets/img/arab-flag.jpg";
      this.CurrentLangName = "عربي";
      this.CurrentLangCode = "ar";
    }


  }

  classApplied = false;
  toggleClassLang() {
    this.classApplied = !this.classApplied;
  }


  setLang() {
    if (this.currentLang === "ar") {
      this.currentLang = "en";
    }
    else {
      this.currentLang = "ar";

    }
    this.localS.setItem('Lang', this.currentLang);
    this.toggleClassLang();


    window.location.reload();
  }

}
