<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url('assets/img/page-title/page-title3.jpg');">
  <div class="container">
    <div class="page-title-content">
      <h2>{{'Lab_Testing_and_Inspection' | translate }}</h2>
      <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li>
        <li>{{'Lab_Testing_and_Inspection' | translate }}</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->
<!-- Start About Area -->
<section class="about-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <h2>{{'Lab_Testing_and_Inspection' | translate }}</h2>

          <p>{{'Lab_Test1TXT' | translate }}</p>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img src="./assets/img/lab_test.jpg" alt="image">
        </div>
      </div>
    </div>
 

  </div>
</section>
<!-- End About Area -->


<app-footer-style-two></app-footer-style-two>
