
export class FeedbackModule {
    wbFeedbackID: number = 0;
    name: string = '';
    phoneNo: string = '';
    email: string = '';
    message: string = '';
    addDate: Date = new Date();
    type: string = '';
    notes: string = '';
    status: string = '';
    gnCategoryID: number = 0;
    completedDate: string = '';
    completedByUserID: number = 0;
    dialing: string = '';
}

export class ViewFeedbackModule {
    wbFeedbackID: number = 0;
    name: string = '';
    phoneNo: string = '';
    email: string = '';
    message: string = '';
    addDate: Date = new Date();
    type: string = '';
    notes: string = '';
    status: string = '';
    gnCategoryID: number = 0;
    completedDate: string = '';
    completedByUserID: number = 0;
    dialing: string = '';
    categoryNameAr: string = '';
    categoryNameEn: string = '';
}