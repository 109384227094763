<!-- Start Category Area -->
<div class="container">
  <div class="section-title" *ngFor="let Title of sectionTitle;">

    <h2>{{'Our_Competitive_Advantages' | translate }}</h2>
    <!--<p>{{'Our_Competitive_AdvantagesTXT' | translate }}</p>-->
  </div>

  <div class="row">
    <div class="col-lg-4 col-sm-6 col-md-4">
      <div class="single-category-box">
        <img src="./assets/img/values/Competitive1.png" />
        <h3>{{'Wide_Coverage' | translate }}</h3>
        <span>{{'Wide_CoverageTXT' | translate }}</span>

      </div>
    </div>
    <div class="col-lg-4 col-sm-6 col-md-4">
      <div class="single-category-box">
        <img src="./assets/img/values/Competitive2.png" />
        <h3>{{'24_7_Communications' | translate }}</h3>
        <span>{{'24_7_CommunicationsTXT' | translate }}</span>

      </div>
    </div>
    <div class="col-lg-4 col-sm-6 col-md-4">
      <div class="single-category-box">
        <img src="./assets/img/values/Competitive3.png" />
        <h3>{{'Ease_of_Use' | translate }}</h3>
        <span>{{'Ease_of_UseTXT' | translate }}</span>

      </div>
    </div>
  </div>
</div>
<!-- End Category Area -->
