<app-navbar-style-one></app-navbar-style-one>

<app-homeone-banner></app-homeone-banner>


<app-features></app-features>


<section class="listings-area ptb-100 bg-f9f9f9">

  <div class="container">
    <app-how-it-works></app-how-it-works>
  </div>
  <div class="divider2"></div>
</section>

<section class="category-area pt-100 pb-70">
  <app-category></app-category>
</section>

<app-footer-style-two></app-footer-style-two>
