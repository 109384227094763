<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url('assets/img/page-title/page-title3.jpg');">
  <div class="container">
    <div class="page-title-content">
      <h2>{{'Certificate_of_Conformity' | translate }}</h2>
      <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li>
        <li>{{'Certificate_of_Conformity' | translate }}</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->
<!-- Start About Area -->
<section class="about-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12">
        <div class="about-content">
          <h2>{{'Certificate_of_Conformity' | translate }}</h2>
          <!--<span><strong>{{Content.subTitle}}</strong></span>-->
          <p>{{'Certificate_of_ConformityTXT' | translate }}</p>
          <p>{{'Certificate_of_Conformity2TXT' | translate }}</p>
        </div>
      </div>
    </div>



    <br />
    <br />

    <div class="section-title">
      <h2>{{'Product_Category' | translate }}</h2>
      <p>{{'Product_CategoryTXT' | translate }}</p>
    </div>


    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-destinations-box color-box-shadow">
          <img src="./assets/img/destinations/destinations1.jpg" alt="image">
          <div class="country">{{'Cosmetics_and_personal_care' | translate }}</div>
          <div class="content">

          </div>

        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-destinations-box color-box-shadow">
          <img src="./assets/img/destinations/destinations2.jpg" alt="image">
          <div class="country">{{'Dish-washing_Liquid' | translate }}</div>
          <div class="content">

          </div>

        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-destinations-box color-box-shadow">
          <img src="./assets/img/destinations/destinations3.jpg" alt="image">
          <div class="country">{{'Paints' | translate }}</div>
          <div class="content">

          </div>

        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-destinations-box color-box-shadow">
          <img src="./assets/img/destinations/destinations4.jpg" alt="image">
          <div class="country">{{'Lead-acid_Starter_batteries' | translate }}</div>
          <div class="content">

          </div>

        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-destinations-box color-box-shadow">
          <img src="./assets/img/destinations/destinations5.jpg" alt="image">
          <div class="country">{{'Safety_Shoes' | translate }}</div>
          <div class="content">

          </div>

        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-destinations-box color-box-shadow">
          <img src="./assets/img/destinations/destinations6.jpg" alt="image">
          <div class="country">{{'Building_and_construction_Materials' | translate }}</div>
          <div class="content">

          </div>

        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-destinations-box color-box-shadow">
          <img src="./assets/img/destinations/destinations8.jpg" alt="image">
          <div class="country">{{'Washing_Detergent_powder' | translate }}</div>
          <div class="content">

          </div>

        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-destinations-box color-box-shadow">
          <img src="./assets/img/destinations/destinations9.jpg" alt="image">
          <div class="country">{{'Aerosol_air_Fresheners' | translate }}</div>
          <div class="content">

          </div>

        </div>
      </div>

      <div class="col-lg-6 col-sm-12 col-md-12">
        <div class="single-destinations-box color-box-shadow">
          <img src="./assets/img/destinations/destinations7.jpg" alt="image">
          <div class="country">{{'Lubricants ' | translate }}</div>
          <div class="content">

          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<!-- End About Area -->


<app-footer-style-two></app-footer-style-two>
