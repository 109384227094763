import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-how-it-works',
    templateUrl: './how-it-works.component.html',
    styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  constructor(public translate: TranslateService) {

    if (this.translate.currentLang != undefined) {

      this.SelectedLanguage = this.translate.currentLang;
    }
  }

  public SelectedLanguage: string = "en";
    ngOnInit(): void {
    }

    //sectionTitle = [
    //    {
    //        title: 'How It Works',
    //        paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.'
    //    }
    //]
    //howItWorksBox = [
    //    {
    //        icon: 'flaticon-placeholder',
    //        title: 'Find Interesting Place',
    //        paragraph: 'Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.'
    //    },
    //    {
    //        icon: 'flaticon-support-1',
    //        title: 'Contact a Few Owners',
    //        paragraph: 'Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.'
    //    },
    //    {
    //        icon: 'flaticon-tick',
    //        title: 'Make a Reservation',
    //        paragraph: 'Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.'
    //    }
    //]

}
