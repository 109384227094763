import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/appviews/home/home.component';
import { BlogComponent } from './components/appviews/blog/blog.component';
import { ServicesComponent } from './components/appviews/services/services.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { AboutUsComponent } from './components/appviews/about-us/about-us.component';
import { ContactComponent } from './components/appviews/contact/contact.component';
import { ServicesISOComponent } from './components/appviews/services/services-iso/services-iso.component';
import { BlogDetailsComponent } from './components/appviews/blog/blog-details/blog-details.component';
import { ServiceLabTestingComponent } from './components/appviews/services/service-lab-testing/service-lab-testing.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'service', component: ServicesComponent },
  { path: 'service-iso', component: ServicesISOComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'service-testing', component: ServiceLabTestingComponent },

    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
