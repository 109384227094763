import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { CategoryModule } from './category.module';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getCategory(type: string): Observable<CategoryModule[]> {
        return this.http.get<CategoryModule[]>(environment.apiURL + 'General/GNCategory/search/' + type);
    }

    getServiceId(id: number): Observable<CategoryModule[]> {
        return this.http.get<CategoryModule[]>(environment.apiURL + 'General/GNCategory/' + id);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'General/GNCategory/' + id).toPromise();
    }

    formData: CategoryModule = new CategoryModule();
    list: CategoryModule[];

    postService() {
        return this.http.post(environment.apiURL + 'General/GNCategory/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'General/GNCategory/' + this.formData.gnCategoryID, this.formData);
    }
}
