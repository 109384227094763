<!-- Start Features Area -->
<section class="features-area ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{'Our_values_define_our_culture' | translate }}</h2>
        <p>{{'Our_values_define_our_cultureTXT' | translate }}</p>
      </div>

        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-features-box">
              <img src="./assets/img/values/Picture1.png" />
              <h3>{{'Impartiality' | translate }}</h3>
              <p>{{'ImpartialityTXT' | translate }}</p>
             
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-features-box">
              <img src="./assets/img/values/Picture2.png" />
              <h3>{{'Transparency' | translate }}</h3>
              <p>{{'TransparencyTXT' | translate }}</p>
            
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-features-box">
              <img src="./assets/img/values/Picture3.png" />
              <h3>{{'Performance_quality' | translate }}</h3>
              <p>{{'Performance_qualityTXT' | translate }}</p>
            
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-features-box">
              <img src="./assets/img/values/Picture4.png" />
              <h3>{{'Responsibility' | translate }}</h3>
              <p>{{'ResponsibilityTXT' | translate }}</p>
            
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-features-box">
              <img src="./assets/img/values/Picture5.png" />
              <h3>{{'Innovation' | translate }}</h3>
              <p>{{'InnovationTXT' | translate }}</p>
            
            </div>
          </div>

              <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-features-box">
              <img src="./assets/img/values/Picture6.png" />
              <h3>{{'Creativity' | translate }}</h3>
              <p>{{'CreativityTXT' | translate }}</p>
            
            </div>
          </div>

              <!--<div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-features-box">
              <img src="./assets/img/values/Picture7.png" />
              <h3>{{'Teamwork' | translate }}</h3>
              <p>{{'TeamworkTXT' | translate }}</p>
            
            </div>
          </div>-->


        </div>
    </div>

    <div class="divider"></div>
</section>
<!-- End Features Area -->
