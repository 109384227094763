import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { HowItWorksComponent } from './components/common/how-it-works/how-it-works.component';
import { FeedbackStyleOneComponent } from './components/common/feedback-style-one/feedback-style-one.component';
import { CategoryComponent } from './components/common/category/category.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { TeamComponent } from './components/common/team/team.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { DashboardNavbarComponent } from './components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from './components/common/dashboard-sidemenu/dashboard-sidemenu.component';


import { HomeComponent } from './components/appviews/home/home.component';
import { BlogComponent } from './components/appviews/blog/blog.component';
import { ServicesComponent } from './components/appviews/services/services.component';
import { AboutUsComponent } from './components/appviews/about-us/about-us.component';
import { ContactComponent } from './components/appviews/contact/contact.component';
import { AppLoaderComponent } from './components/common/app-loader/app-loader.component';
import { AppComfirmComponent } from './components/common/app-confirm/app-confirm.component';
import { HomeoneBannerComponent } from './components/appviews/home/homeone-banner/homeone-banner.component';
import { ServicesISOComponent } from './components/appviews/services/services-iso/services-iso.component';
import { BlogDetailsComponent } from './components/appviews/blog/blog-details/blog-details.component';
import { ServiceLabTestingComponent } from './components/appviews/services/service-lab-testing/service-lab-testing.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  declarations: [
    AppComponent,
    AppDownloadComponent,
    HowItWorksComponent,
    FeedbackStyleOneComponent,
    CategoryComponent,
    FeaturesComponent,
    NavbarStyleOneComponent,
    NavbarStyleTwoComponent,
    NotFoundComponent,
    AboutUsComponent,
    PartnerComponent,
    TeamComponent,
    FunfactsComponent,
    ContactComponent,
    FooterStyleTwoComponent,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    HomeComponent,
    BlogComponent,
    BlogDetailsComponent,
    ServicesComponent,
        AppLoaderComponent,
    AppComfirmComponent,
    HomeoneBannerComponent,
    ServicesISOComponent,
    ServiceLabTestingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    SelectDropDownModule,
    NgxTypedJsModule,
    FormsModule,
    NgxPaginationModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    ToastrModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
