<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url('assets/img/page-title/page-title3.jpg');">
  <div class="container">
    <div class="page-title-content">
      <h2>{{'ISO_Certificates' | translate }}</h2>
      <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li>
        <li>{{'ISO_Certificates' | translate }}</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->
<!-- Start About Area -->
<section class="about-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <h2>{{'ISO_Certificates' | translate }}</h2>

          <p>{{'ISO_CertificatesTXT' | translate }}</p>
          <p>{{'ISO_Certificates2TXT' | translate }}</p>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img src="./assets/img/iso-img.jpg" alt="image">
        </div>
      </div>
    </div>
    <div class="row align-items-center">

      <p>{{'ISO_Certificates3TXT' | translate }}</p>
      <ul>

        <li>{{'ISO_CertificatesList1TXT' | translate }}</li>
        <li>{{'ISO_CertificatesList2TXT' | translate }}</li>
        <li>{{'ISO_CertificatesList3TXT' | translate }}</li>
        <li>{{'ISO_CertificatesList4TXT' | translate }}</li>
      </ul>
    </div>

    <br />
    <br />
    <div class="row align-items-center">


      <div class="section-title">
        <h2>{{'ISO_Certificates4TXT' | translate }}</h2>
      </div>
      <ul>

        <li>{{'ISO_CertificatesBenefitsList1TXT' | translate }}</li>
        <li>{{'ISO_CertificatesBenefitsList2TXT' | translate }}</li>
        <li>{{'ISO_CertificatesBenefitsList3TXT' | translate }}</li>
        <li>{{'ISO_CertificatesBenefitsList4TXT' | translate }}</li>
        <li>{{'ISO_CertificatesBenefitsList5TXT' | translate }}</li>
        <li>{{'ISO_CertificatesBenefitsList6TXT' | translate }}</li>
        <li>{{'ISO_CertificatesBenefitsList7TXT' | translate }}</li>
        <li>{{'ISO_CertificatesBenefitsList8TXT' | translate }}</li>
        <li>{{'ISO_CertificatesBenefitsList8TXT' | translate }}</li>
        <li>{{'ISO_CertificatesBenefitsList10TXT' | translate }}</li>
      </ul>
    </div>


    <br />
    <br />
    <div class="row align-items-center">


      <div class="section-title">
        <h2>{{'ISO_Certificates5TXT' | translate }}</h2>
      </div>
      <ul>

        <li>{{'ISO_CertificatesTechList1TXT' | translate }}</li>
        <li>{{'ISO_CertificatesTechList2TXT' | translate }}</li>
        <li>{{'ISO_CertificatesTechList3TXT' | translate }}</li>
        <li>{{'ISO_CertificatesTechList4TXT' | translate }}</li>
        <li>{{'ISO_CertificatesTechList5TXT' | translate }}</li>
      </ul>
    </div>

    <div class="row align-items-center">

      <p>{{'ISO_Certificates6TXT' | translate }}</p>
    </div>
  </div>
</section>
<!-- End About Area -->


<app-footer-style-two></app-footer-style-two>
