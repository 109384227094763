<!-- Start How It Works Area -->
<div class="container">
  <div class="section-title">

    <h2>{{'How_It_Works' | translate }}</h2>
    <p>{{'How_It_WorksTXT' | translate }}</p>
  </div>

  <div class="row" *ngIf="SelectedLanguage == 'en'">
    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/info.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works1' | translate }}</h3>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/testSample.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works2' | translate }}</h3>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/check-icon.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works3' | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="SelectedLanguage == 'en'">
    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/certficate.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works4' | translate }}</h3>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/issue.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works5' | translate }}</h3>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/ship.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works6' | translate }}</h3>
      </div>
    </div>
  </div>








  <div class="row" *ngIf="SelectedLanguage == 'ar'">
    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/info.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works1' | translate }}</h3>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/TestSample.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works2' | translate }}</h3>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/check-icon.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works3' | translate }}</h3>
      </div>
    </div>

  </div>

  <div class="row" *ngIf="SelectedLanguage == 'ar'">

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/certficate.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works4' | translate }}</h3>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/issue.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works5' | translate }}</h3>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="single-how-it-works-box">
        <div class="icon">
          <img src="./assets/img/values/ship.png" style="height:40px" />
        </div>
        <h3>{{'How_It_Works6' | translate }}</h3>
      </div>
    </div>

  </div>


</div>
<!-- End How It Works Area -->
