import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { FeedbackModule, ViewFeedbackModule } from './feedback.module';
@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getCategory(type: string): Observable<FeedbackModule[]> {
        return this.http.get<FeedbackModule[]>(environment.apiURL + 'WebTools/WBFeedback/search/' + type);
    }

    getServiceId(id: number): Observable<FeedbackModule[]> {
        return this.http.get<FeedbackModule[]>(environment.apiURL + 'Manage/WBFeedback/' + id);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Manage/WBFeedback/' + id).toPromise();
    }

    formData: FeedbackModule = new FeedbackModule();
    list: FeedbackModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Manage/WBFeedback/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Manage/WBFeedback/' + this.formData.wbFeedbackID, this.formData);
    }


    getServiceVWStatus(status: string): Observable<ViewFeedbackModule[]> {
        return this.http.get<ViewFeedbackModule[]>(environment.apiURL + 'WebTools/WBFeedback/Search/' + status);
    }
}
