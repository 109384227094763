<!-- Start Footer Area -->
<footer class="footer-area bg-f5f5f5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-sm-12 col-md-6">
          <div class="single-footer-widget">
            <h3>{{'AboutTawtheeq' | translate }}</h3>

            <p>{{'FooterAboutTXT' | translate }}</p>

            
                <img src="assets/img/black-logo.png" alt="hekaya-logo" class="hekaya-logo">
           

          </div>
        </div>
        

        <div class="col-lg-2 col-sm-6 col-md-6">
          <div class="single-footer-widget">
            <h3>{{'QuickLinks' | translate }}</h3>

            <ul class="link-list">
              <li><a routerLink="/about"><i class="flaticon-left-chevron"></i> {{'AboutTawtheeq' | translate }}</a></li>
              <li><a routerLink="/service"><i class="flaticon-left-chevron"></i> {{'OurServices' | translate }}</a></li>
              <li><a routerLink="/blog"><i class="flaticon-left-chevron"></i> {{'Blog' | translate }}</a></li>
              <li><a routerLink="/contact"><i class="flaticon-left-chevron"></i> {{'ContactUs' | translate }}</a></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-footer-widget">
            <h3>{{'ContactInfo' | translate }}</h3>

            <ul class="footer-contact-info">
              <li><i class="bx bx-map"></i>{{'OurAddressTXT' | translate }}</li>
              <li><i class="bx bx-phone-call"></i><a href="tel:+96893525921" dir="ltr">+968 93525921</a></li>
              <li><i class="bx bx-envelope"></i><a href="mailto:contact@tawtheeqconformity.com">contact@tawtheeqconformity.com</a></li>
              <li><i class="bx bxs-inbox"></i><a href="tel:+96822650787" dir="ltr">+968 22650787</a></li>
            </ul>

            <ul class="social-link">
              <li><a href="https://twitter.com/tawtheeqtc?s=21" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a></li>
              <li><a href="https://www.instagram.com/tawtheeqconformity/?utm_medium=copy_link" class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a></li>
              <li><a href="https://www.linkedin.com/company/tawtheeq-conformity" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
            </ul>
          </div>
        </div>
      </div>

        <div class="copyright-area" dir="ltr">
          <p>Copyright 2023 Tawtheeq Conformity All rights reserved | Powered By <a href="http://d-pioneers.com/" target="_blank">Digital Pioneers.</a></p>
          <br />
        </div>
    </div>

    <!--<div class="footer-image text-center">
        <img src="assets/img/footer-image.png" alt="image">
    </div>-->
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>
