import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AddressIPsModule } from './address-ips.module';

@Injectable({
  providedIn: 'root'
})
export class AddressIPsService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getCategory(type: string): Observable<AddressIPsModule[]> {
        return this.http.get<AddressIPsModule[]>(environment.apiURL + 'WebTools/WBAddressIPs/search/' + type);
    }

    getServiceId(id: number): Observable<AddressIPsModule[]> {
        return this.http.get<AddressIPsModule[]>(environment.apiURL + 'Manage/WBAddressIPs/' + id);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Manage/WBAddressIPs/' + id).toPromise();
    }

    formData: AddressIPsModule = new AddressIPsModule();
    list: AddressIPsModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Manage/WBAddressIPs/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Manage/WBAddressIPs/' + this.formData.wbAddressIPsID, this.formData);
    }



    getCountryChart() {

        return this.http.get<any>(environment.apiURL + 'WebTools/WBAddressIPs/searchCountry');

    }


    getMonthChart() {

        return this.http.get<any>(environment.apiURL + 'WebTools/WBAddressIPs/searchMonth');

    }

    getServiceDashboard() {

        return this.http.get(environment.apiURL + "WebTools/WBAddressIPs/Dashboard/", { responseType: 'text' });
    }
}
