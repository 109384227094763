import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { BlogModule } from './blog.module';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getServiceAll(): Observable< BlogModule[]> {
        return this.http.get< BlogModule[]>(environment.apiURL + 'WebTools/WBBlog/' );
    }

    getServiceId(id: number): Observable< BlogModule[]> {
        return this.http.get< BlogModule[]>(environment.apiURL + 'WebTools/WBBlog/' + id);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'WebTools/WBBlog/' + id).toPromise();
    }

    formData:  BlogModule = new  BlogModule();
    list:  BlogModule[];

    postService() {
        return this.http.post(environment.apiURL + 'WebTools/WBBlog/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'WebTools/WBBlog/' + this.formData.wbBlogID, this.formData);
    }




    postServiceWithFile(files) {
        if (files.length === 0) {
            return;
        }
        const formData = new FormData();
        let fileToUpload = <File>files[0];

        formData.append('filePath', fileToUpload, fileToUpload.name);
        formData.append('titleAr', this.formData.titleAr.toString());
        formData.append('titleEn', this.formData.titleEn.toString());
        formData.append('contentAr', this.formData.contentAr.toString());
        formData.append('contentEn', this.formData.contentEn.toString());
        formData.append('addDate', this.formData.addDate.toString());
        formData.append('addBy', this.formData.addBy.toString());
        formData.append('isVisible', this.formData.isVisible.toString());

        return this.http.post(environment.apiURL + 'WebTools/WBBlog/PostUpload', formData, { reportProgress: true, observe: 'events' });
    }

    putServiceWithFile(files) {
        if (files.length === 0) {
            return;
        }
        let fileToUpload = <File>files[0];
        const formData = new FormData();

        formData.append('filePath', fileToUpload, fileToUpload.name);
        formData.append('wbBlogID', this.formData.wbBlogID.toString());
        formData.append('titleAr', this.formData.titleAr.toString());
        formData.append('titleEn', this.formData.titleEn.toString());
        formData.append('contentAr', this.formData.contentAr.toString());
        formData.append('contentEn', this.formData.contentEn.toString());
        formData.append('addDate', this.formData.addDate.toString());
        formData.append('addBy', this.formData.addBy.toString());
        formData.append('isVisible', this.formData.isVisible.toString());

        return this.http.put(environment.apiURL + 'WebTools/WBBlog/PutUpload', formData, { reportProgress: true, observe: 'events' });
    }
}
