import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
    template: `<h1  class="mb-8">{{'Confirm' | translate }}</h1>
    <div class="mb-16">{{'Are_you_sure_you_want_to_delete_this_person' | translate }}</div>
    <div class="pb-16">

 <div class="listing-edit-delete-option">
                    <div class="d-flex justify-content-between align-items-center">

    <button
    type="button"
    class="default-btnBlack"  style="margin: 20px"
    (click)="dialogRef.close(true)">{{'Yes' | translate }}</button>
 

    <button
    type="button"
    class="default-btnWhite"
    (click)="dialogRef.close(false)">{{'No' | translate }}</button>

</div>
</div>
    </div>`,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
     // this.dialogRef.d

  }
}
