
export class BlogModule {
    wbBlogID: number = 0;
    titleAr: string = '';
    titleEn: string = '';
    contentAr: string = '';
    contentEn: string = '';
    addDate: Date = new Date();
    addBy: string = '';
    isVisible: number = 0;
    imageLink: string = '';
   
}
