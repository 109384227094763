<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="vesax-responsive-nav">
        <div class="container">
            <div class="vesax-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/black-logo.png" alt="logo" style="height:60px"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="vesax-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/black-logo.png" alt="logo" style="height:60px"></a>

                <div class="collapse navbar-collapse mean-menu">


                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="font-weight: bold; font-size: 18px">{{'Home' | translate }}</a>
                    </li>
                    <li class="nav-item">
                      <a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="font-weight: bold; font-size: 18px">{{'AboutUs' | translate }}</a>
                    </li>
                    <li class="nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link" style="font-weight: bold; font-size: 18px">{{'OurServices' | translate }}</a>
                      <ul class="dropdown-menu">
                        <li class="nav-item"><a routerLink="/service" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="font-weight: bold; font-size: 18px">{{'Certificate_of_Conformity' | translate }}</a></li>

                        <li class="nav-item"><a routerLink="/service-iso" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="font-weight: bold; font-size: 18px">{{'ISO_Certificates' | translate }}</a></li>
                        <li class="nav-item"><a routerLink="/service-testing" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="font-weight: bold; font-size: 18px">{{'Lab_Testing_and_Inspection' | translate }}</a></li>

                      </ul>
                    </li>
                    <li class="nav-item">
                      <a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="font-weight: bold; font-size: 18px">{{'Blog' | translate }}</a>
                    </li>
                    <li class="nav-item">
                      <a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="font-weight:bold; font-size:18px">{{'Contact' | translate }}</a>
                    </li>





                  </ul>
                  <div class="others-option d-flex align-items-center">

                    <div class="dropdown language-switcher d-inline-block">
                      <button class="dropdown-toggle" type="button" (click)="toggleClassLang()">
                        <img src="{{CurrentLangSrc}}" alt="image">
                        <span>{{CurrentLangName}} <i class='bx bx-chevron-down'></i></span>
                      </button>
                      <div class="dropdown-menu" [class.active]="classApplied">
                        <button (click)="setLang()" class="dropdown-item d-flex align-items-center">
                          <img src="{{SecondLangSrc}}" class="shadow-sm" alt="flag">
                          <span>{{SecondLangName}}</span>
                        </button>

                      </div>
                    </div>


                  </div>


                </div>
            </nav>
        </div>
    </div>

    
</div>
<!-- End Navbar Area -->

<!-- Start Login & Register Modal -->
