import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { BlogModule } from '../../../appshared/webTools/blog/blog.module';
import { BlogService } from '../../../appshared/webTools/blog/blog.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  constructor(public service: BlogService, public translate: TranslateService, private route: ActivatedRoute) {
    if (this.translate.currentLang != undefined) {

      this.SelectedLanguage = this.translate.currentLang;
    }

  }
  public SelectedLanguage: string = "en";

  thisID: number = 0;
  private sub: any;
  private datas: any;
  public apiURL: string = "";

  ngOnInit(): void {
    this.apiURL = environment.apiUrl2 + 'Resources/Attachment/Order/';
    this.sub = this.route.params.subscribe(params => {
      this.thisID = +params['id'];
    });
    this.getAllService();
  }

  public PageContentHTML: string = "";
  public PageTitle: string = "";

  public getAllService = () => {
    /*    this.isLoading = true;*/
    //this.loader.open();
    this.service.getServiceId(this.thisID)
      .subscribe(res => {
        //this.loader.close();
        this.datas = res as BlogModule[];

        this.service.formData.contentAr = this.datas.contentAr;
        this.service.formData.contentEn = this.datas.contentEn;
        this.service.formData.isVisible = this.datas.isVisible;
        this.service.formData.wbBlogID = this.datas.wbBlogID;
        this.service.formData.titleAr = this.datas.titleAr;
        this.service.formData.titleEn = this.datas.titleEn;
      
        if (this.translate.currentLang == "ar") {

          this.PageContentHTML = this.service.formData.contentAr;
          this.PageTitle = this.service.formData.titleAr;
        }
        else {

          this.PageContentHTML = this.service.formData.contentEn;
          this.PageTitle = this.service.formData.titleEn;
        }


      },
        err => {
         // this.loader.close(); console.log(err);
        });

    /*  this.isLoading = false;*/
  }
}
