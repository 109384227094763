<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-style-two" style="background-image: url('assets/img/page-title/page-title4.jpg');">
  <div class="container">
    <div class="page-title-content">
      <h2>{{'Blog' | translate }}</h2>
      <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li>
        <li>{{'Blog' | translate }}</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->
<!-- Start Blog Details Area -->
<section class="blog-details-area bg-f9f9f9 ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="blog-details-desc">
          <div class="article-image">
            <img [src]="apiURL + service.formData.imageLink" alt="image">
          </div>

          <div class="article-content">
            <div class="entry-meta">
              <ul>
                <li>
                  <i class='bx bx-group'></i>
                  <span>{{'Author' | translate }}</span>
                  {{ service.formData.addBy  }}
                </li>
                <li>
                  <i class='bx bx-calendar'></i>
                  <span>{{'Date' | translate }}</span>
                  {{service.formData.addDate | date  : 'dd/MM/yyyy'}}
                </li>
              </ul>
            </div>
            <h3 [innerHtml]="PageTitle"></h3>
            <div [innerHtml]="PageContentHTML"></div>




          </div>
        </div>


      </div>
    </div>
  </div>
</section>
<!-- End Blog Details Area -->

<app-footer-style-two></app-footer-style-two>
